@font-face {
	font-family: Compass Sans;
	font-style: normal;
	font-weight: 400;
	font-display: fallback;
	src: url(/fonts/CompassSans-Regular.woff2) format("woff2"), 
         url(/fonts/CompassSans-Regular.woff) format("woff"),
         url(/fonts/CompassSans-Regular.ttf) format("truetype")
}

@font-face {
	font-family: Compass Sans;
	font-style: normal;
	font-weight: 500;
	font-display: fallback;
	src: url(/fonts/CompassSans-Medium.woff2) format("woff2"),
         url(/fonts/CompassSans-Medium.woff) format("woff"),
         url(/fonts/CompassSans-Medium.ttf) format("truetype")
}

@font-face {
	font-family: Compass Sans;
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src: url(/fonts/CompassSans-Bold.woff2) format("woff2"),
         url(/fonts/CompassSans-Bold.woff) format("woff"), 
         url(/fonts/CompassSans-Bold.ttf) format("truetype")
}

@font-face {
	font-family: Compass Serif;
	font-style: normal;
	font-weight: 400;
	font-display: fallback;
	src: url(/fonts/CompassSerif-Regular.woff2) format("woff2"), 
         url(/fonts/CompassSerif-Regular.woff) format("woff"),
         url(/fonts/CompassSerif-Regular.ttf) format("truetype")
}

@font-face {
	font-family: Compass Serif;
	font-style: normal;
	font-weight: 600;
	font-display: fallback;
	src: url(/fonts/CompassSerif-SemiBold.woff2) format("woff2"),
         url(/fonts/CompassSerif-SemiBold.woff) format("woff"), 
         url(/fonts/CompassSerif-SemiBold.ttf) format("truetype")
}

@font-face {
	font-family: Compass Serif;
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src: url(/fonts/CompassSerif-Bold.woff2) format("woff2"),
         url(/fonts/CompassSerif-Bold.woff) format("woff"),
         url(/fonts/CompassSerif-Bold.ttf) format("truetype")
}


.font-sansRegular {
	font-weight:400;
}

.font-sansMedium,
.font-sansRegular {
	font-family: Compass Sans, Helvetica, Arial, sans-serif;
	font-style: normal;
    font-weight:400;
}

.font-sansMedium {
	font-weight: 500;
    font-family: Compass Sans, Helvetica, Arial, sans-serif;
}

.font-sansBold {
	font-family: Compass Sans, Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-style: normal
}

.font-serifRegular {
	font-weight: 400;
    font-family: Compass Serif, Times, Times New Roman, serif;
}

.font-serifRegular,
.font-serifSemiBold {
	font-family: Compass Serif, Times, Times New Roman, serif;
	font-style: normal
}

.font-serifSemiBold {
	font-weight: 600;
    font-family: Compass Serif, Times, Times New Roman, serif;
}

.font-serifBold {
	font-family: Compass Serif, Times, Times New Roman, serif;
	font-weight: 700;
	font-style: normal
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility
}




